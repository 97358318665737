<template>
  <Layout>
    <Lottie :path="'animate/receipt.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" :timer="3000" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <swiper-container class="mySwiper2 mb-3 card"  navigation="false" space-between="30" direction="vertical"
    centered-slides="true" autoplay-delay="2500" autoplay-disable-on-interaction="false" style="height: 54px;">
          <swiper-slide class="text-muted w-100" v-for="(value, index) in announcement" :key="index" >
            <div class="p-3 topNotification"><i class="mdi mdi-bullhorn font-size-15 me-2"></i> {{ value.name }}  {{ value.spent }} {{$t('siteLang.JustBorrowed')}}  {{ value.money }}</div>
          </swiper-slide>
        </swiper-container>
        <div class="card rounded p-3">
          <div class="border-bottom pb-2 mb-2">
            <div class="row">
              <div class="col-auto">
                <div class="fw-medium text-dark">{{$t('siteLang.MaximumLoanAmount')}}</div>
                <div class="fw-medium text-primary font-size-16">Rp. {{sliderWithLabel.range[1].label}}</div>
              </div>
              <div class="col-auto ms-auto text-end">
                <div class="fw-normal text-muted">{{$t('siteLang.InterestRate')}}</div>
                <div class="fw-medium text-dark font-size-16">{{InterestRate}}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-auto"> <div class="fw-medium text-dark">{{$t('siteLang.LoanAmount')}} (Rp)</div></div>
              <div class="col-auto ms-auto"><div class="fw-medium text-primary font-size-16">Rp. {{ LoanAmout }}</div> </div>
              <div class="col-12">
                <div class="px-4">
                    <vue-slide-bar
                      class="vue-slide-bar-style"
                      v-model="LoanAmout"
                      :data="sliderWithLabel.data"
                      :range="sliderWithLabel.range"
                      :min="20000000"
                      :max="900000000"
                      @dragStart="dragStartFunc"
                      @dragEnd="dragEndFunc"
                    />
                    <!-- @callbackRange="callbackRange" -->
                    <div class="text-center mb-2">

                      <div class="btn-group" role="group" >
                        <button type="button" class="btn btn-sm btn-outline-primary" @click="increaseDescrease('-')" :disabled="LoanAmout.replaceAll(',','') == 2000000" ><i class="uil uil-minus"></i></button>
                        <button type="button" class="btn btn-sm btn-outline-primary" @click="increaseDescrease('+')" :disabled="LoanAmout.replaceAll(',','') == 50000000"><i class="uil uil-plus"></i></button>
                      </div>
                   
                  </div>
                  </div>
                  
              </div>
              <div class="col-12">
                <div class="fw-medium text-dark mb-2">{{$t('siteLang.LoanTerm')}}</div>
                <div class="row px-2 mb-3">
                  <div class="col-6 col-md-3 px-1" v-for="(value, index) in period" :key="index">
                    <input type="radio" class="btn-check" name="period" @change="periodChange" :id="index" autocomplete="off" :value="value" v-model="period_selected" >
                    <label class="btn w-100 fw-medium" 
                    :class="{
                      'btn-outline-primary':period_selected==value,
                      'btn-outline-secondary':period_selected!==value
                    }" :for="index">{{value.label + ' ' + $t('siteLang.Months') }}</label>
                  </div>
                </div> <!--row-->
              </div> <!--col-12-->
              <div class="col-auto">
                <div class="fw-medium text-dark">{{$t('siteLang.MonthlyRepayment')}} </div>
              </div><!--col-->
              <div class="col-auto ms-auto">
                <div class="fw-medium text-primary font-size-16">Rp. {{ monthlyRepayment }} ({{ InterestRate }}) </div> 
              </div><!--col-->
              <div class="col-12 my-3">
                <button class="btn btn-primary w-100 btn-lg fw-medium" @click="applyLoan" type="button" :disabled="loanStatus!==99">{{$t('siteLang.ApplyForLoan')}}</button>
              </div>
            </div>
            <div class="row justify-content-center align-items-center my-2 button_bottom_info">
              <div class="col-auto">
                <i class="mdi mdi-checkbox-marked-circle font-size-15 me-1"></i> {{$t('siteLang.ApplyLoan')}}
              </div>
              <div class="col-auto ">
                <i class="mdi mdi-checkbox-marked-circle font-size-15 me-1"></i> {{$t('siteLang.SubmitInfo')}}
              </div>
              <div class="col-auto">
                <i class="mdi mdi-checkbox-marked-circle font-size-15 me-1"></i> {{$t('siteLang.GetMoney')}}
              </div>
            </div>
          </div>
        </div>
        <swiper-container class="mySwiper mb-2 pb-4" space-between="0" slides-per-view="auto" autoplay-delay="3000">
          <!-- pagination="true"
          pagination-clickable="true" -->
          <swiper-slide v-for="(value, index) in slider" :key="index" style="width:320px" class="mb-1" ><img :src="value.banner" class="img-fluid" ></swiper-slide>
        </swiper-container>
        <!-- <button @click="showLottieView" class="btn btn-primary">show</button> -->
       </div>
    </div>

    <!-- <Stat /> -->
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
//import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import Stat from "@/components/widgets/stat";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import moment from "moment";

export default {
  page() {
    return {
      title: this.$t('siteLang.Home'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  components: {
    Layout,
    //PageHeader,
    Lottie,
    Common,
  },
  data() {
    return {
      showLottie:false,
      title: "",
      items: [
        {
          text: appConfig.title,
        },
        {
          text: "",
          active: true,
        },
      ],
      announcement:[
        {
          "name":"luckxxxx luckxxxx",
          "spent":"5 mins",
          "money":" Rp. 37,000,000"
        },
        {
          "name":"mohhxxxx",
          "spent":"7 mins",
          "money":" Rp. 28,000,000"
        },
        {
          "name":"yeoxxxx",
          "spent":"10 mins",
          "money":" Rp. 50,000,000"
        },
      ],
      slider:[
        {
        banner:require("@/assets/images/app/banner1.png"),
        link:""
      },
      {
        banner:require("@/assets/images/app/banner2.png"),
        link:""
      },
      {
        banner:require("@/assets/images/app/banner1.png"),
        link:""
      },
      {
        banner:require("@/assets/images/app/banner2.png"),
        link:""
      }
      ],
      InterestRate:"0.5%",
      LoanAmout:"38,000,000",
      monthlyRepayment:"",
      sliderWithLabel: {
        data: [],
        range: [
          {
            label: "2,000,000",
          },
          {
            label: "500,000,000",
          },
        ],
        rangeValue: {},
      },
      period:[
        {
          label: "12",
          value: "12",
        },
        {
          label: "18",
          value: "18",
        },
        {
          label: "24",
          value: "24",
        },
        {
          label: "36",
          value: "36",
        },
      ],
      period_selected:{ "label": "12", "value": "12" },
      loanStatus:99
    };
  },
  watch: {
    LoanAmout: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue)
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.LoanAmout = this.$refs.commonFunc.convertCurrencyFormat(newValue).replaceAll(".00","")
        this.getMonthlyReplacement()
      },
      deep: true
    }
  },
  mounted(){
    this.title = this.$t('siteLang.Home')
    this.items[1].text = this.$t('siteLang.Home') 
    for (let i = 2000000; i <= 500000000; i += 1000000) {
      this.sliderWithLabel.data.push(this.$refs.commonFunc.convertCurrencyFormat(i).replaceAll(".00",""))
    }
    this.getMonthlyReplacement()
    this.loanStatus = 99
  },
  created(){
    //encryptStorage.clear();
    this.$Progress.start();
    this.$Progress.finish();
    this.period_selected = this.period[0]
  },
  
  methods:{
    increaseDescrease(type){
      var loanAmout = parseInt(this.LoanAmout.replaceAll(",",""))
      if (type=='+'){
        loanAmout += 1000000
        console.log(loanAmout)
        this.LoanAmout = loanAmout
      }else if (type=='-'){
        loanAmout -= 1000000
        this.LoanAmout = loanAmout
      }
    },
    dragEndFunc(){
      console.log("drag end..")
      document.body.style.overflow = "auto";
    },
    dragStartFunc(){
      console.log("draging..")
      document.body.style.overflow = "hidden";
    },
    showLottieView(){
      this.showLottie = true
      this.$refs.lottieFunc.showHide = true
    },
    callbackRange(val) {
      console.log(val)
    },
    periodChange(){
      this.getMonthlyReplacement()
    },
    getMonthlyReplacement(){
      const loanAmount = this.currencyToNumber(this.LoanAmout);
      const period = parseInt(this.period_selected.value);
      const percent2Decimal = parseFloat(this.InterestRate)*period / 100;
      const interestRate = percent2Decimal;
      const interest = (loanAmount * interestRate);
      const monthlyRepayment = (loanAmount + interest) / period;
      console.log(`-------------------------`)
      console.log(`interestRate(贷款利率) = interestRate(贷款利率)~${parseFloat(this.InterestRate)}* term(贷款期限)~${period} = ${interestRate}%`)
      console.log(`interest(贷款利息)= loanAmount(贷款) * interestRate(贷款利率)`)
      console.log(`loanAmount(贷款)~${loanAmount}, interestRate(贷款利率)~${interestRate}, term(贷款期限)~${period}, interest(贷款利息)~${interest}*`)
      console.log(`${loanAmount}+${interest}/ ${period} *(loanAmount(贷款) +  interest(贷款利息)) / term(贷款期限)`)
      console.log(`monthlyRepayment(每月还款): ${monthlyRepayment}`)
      console.log(`monthlyRepayment(每月还款)*进位: ${this.$refs.commonFunc.convertCurrencyFormat(parseFloat(monthlyRepayment))}`)
      console.log(`-------------------------`)
      this.monthlyRepayment = this.$refs.commonFunc.convertCurrencyFormat(parseFloat(monthlyRepayment))
    },
    currencyToNumber(currency) {
      return parseInt(currency.replace(/[^0-9.-]+/g, ""));
    },
    applyLoan(){
      const now = new Date();
      // const year = now.getFullYear();
      // const month = (now.getMonth() + 1).toString().padStart(2, '0');
      // const day = now.getDate().toString().padStart(2, '0');
      // const hours = now.getHours().toString().padStart(2, '0');
      // const minutes = now.getMinutes().toString().padStart(2, '0');
      // const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
      // console.log(formattedDateTime);

      // const now = new Date();
      // const options = { dateStyle: 'medium', timeStyle: 'medium', timeZone: 'Asia/Jakarta' };
      // const formattedDateTime = now.toLocaleString('en-US', options);
      // console.log(formattedDateTime);
      var DateTime = moment(now).format("YYYY-MM-DD HH:mm")
      var data={
        loanAmount: this.LoanAmout,
        loanPeriod: this.period_selected.value,
        interestRate: this.InterestRate,
        monthlyRepayment: this.monthlyRepayment,
        loanDate:DateTime
      }
      console.log(data);
      sessionStorage.setItem('applyLoan', JSON.stringify(data));
      this.$router.push({ name: 'apply loan'})
    }
     
  }
}

   
</script>
<style>
.swiper-pagination .swiper-pagination-bullet-active{
 background: #04DA8C !important;
}
.landingPage{
  z-index: 60;
}
</style>
<style scoped>
.button_bottom_info, .button_bottom_info i{
  color:#A7A9AC;
}
.vue-slide-bar-style{
  z-index: 5;
  min-height: auto !important;
  margin-bottom: 2px;
}
.topNotification {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-height: 2.8em;
  line-height: 1.5em;
}
</style>